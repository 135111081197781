import React from "react";

import BaseTable from "./BaseTable";
import ViewEmailCell from "./cells/ViewEmailCell";

import moment from "moment";

import { Link } from "react-router-dom";

import ActionCell from "./cells/ActionCell";

import CreateEmailModal from "../modals/CreateEmailModal";
import EmailModal from "../modals/EmailModal";

import EmailActivityTable from "./EmailActivityTable";

export default class EmailTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh());
  }

  _refresh() {
    this.table.current.refresh();
  }

  _getColumns() {
    return [
      {
        Header: "Subject",
        accessor: "subject",
        sortable: false,
      },
      {
        Header: "Sent",
        id: "issued_at",
        accessor: (email) => moment(email.issued_at).format("DD MMM YYYY"),
        type: "date",
        format: "%d %b %Y",
        sortable: false,
      },
      {
        Header: "Recipients",
        accessor: "total_recipients",
        sortable: false,
      },
      {
        Header: "Opened",
        accessor: "total_opened",
        sortable: false,
      },
      {
        id: "active",
        Header: "",
        sortable: false,
        width: 50,
        exportable: false,
        Cell: (rowInfo) => {
          return (
            <ViewEmailCell
              email={rowInfo.original}
              onClick={(email) => {
                this.setState({
                  showViewEmailModal: true,
                  selectedEmail: email,
                });
              }}
            />
          );
        },
      },
    ];
  }

  _renderToolbar() {
    return (
      <button
        onClick={() => this.setState({ showCreateEmailModal: true })}
        className="btn btn-primary my-5"
        data-bs-toggle="modal"
        data-bs-target="#add-email"
      >
        <span className="btn-label">Create Email</span>
        <span className="svg-icon btn-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z"
              fill="black"
            />
            <path
              d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z"
              fill="black"
            />
          </svg>
        </span>
      </button>
    );
  }

  _renderEmailDetails(email) {
    return (
      <EmailActivityTable
        email={email}
        showExport={false}
      />
    );
  }

  render() {
    let {} = this.state;
    const columns = this._getColumns();

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Mail}
          showFilter={false}
          className="card mb-5 mb-xl-8"
          noDataMessage={"No emails found"}
          title={this.props.title}
          renderToolbar={() => this._renderToolbar()}
          SubComponent={(row) => this._renderEmailDetails(row.original)}
          columns={columns}
          showExport={false}
          showPagination={false}
        />
        <CreateEmailModal
          show={this.state.showCreateEmailModal}
          onHide={() => this.setState({ showCreateEmailModal: false })}
          onUpdated={() =>
            this.setState({ showCreateEmailModal: false }, () =>
              this._refresh()
            )
          }
        />
        <EmailModal
          show={this.state.showViewEmailModal}
          email={this.state.selectedEmail}
          onHide={() =>
            this.setState({ showViewEmailModal: false, selectedEmail: null })
          }
          onUpdated={() =>
            this.setState(
              { showViewEmailModal: false, selectedEmail: null },
              () => this._refresh()
            )
          }
        />
      </div>
    );
  }
}

EmailTable.defaultProps = {};
