import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()

window.Api = {}

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {}

window.Api.User.Base = window.Api.Base + '/user'

window.Api.User.Login = window.Api.User.Base + '/login'
window.Api.User.Logout = window.Api.User.Base + '/logout'
window.Api.User.Info = window.Api.User.Base + '/info'
window.Api.Hashes = window.Api.Base + '/hashes'
window.Api.User.RequestResetPassword =
window.Api.User.Base + '/requestResetPassword'
window.Api.User.ResetPassword = window.Api.User.Base + '/resetPassword'
window.Api.User.RefreshToken = window.Api.User.Base + '/login/refresh'

window.Api.Admin = window.Api.Base + '/admin'
window.Api.CreditRatings = window.Api.Base + '/creditRatings'
window.Api.FinancialStats = window.Api.Base + '/financialStats'
window.Api.Reports = window.Api.Base + '/reports'
window.Api.Stats = window.Api.Base + '/stats'
window.Api.UpcomingResults = window.Api.Base + '/upcomingResults'
window.Api.Mail = window.Api.Base + '/mails'
window.Api.Investors = window.Api.Base + '/investor'
window.Api.InvestorSections = window.Api.Base + '/investorSections'
window.Api.InvestorActivities = window.Api.Base + '/investorActivities'

window.Api.Images = window.Api.Base + '/images'
window.Api.Files = window.Api.Base + '/files'
window.Api.FilesV2 = window.Api.Base + '/files/v2'
window.Api.MFA = window.Api.Base + '/mfa'

