import React from 'react'

export default class YearPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: props.activeItem,
      isLoading: true,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _onItemClicked(item) {
    this.setState({ activeItem: item }, () => {
      this.props.onItemClicked(item)
    })
  }

  _renderItems() {
    var max = new Date().getFullYear()
    if (this.props.startYear) {
      max = this.props.startYear
    }
    var min = max - 2 // Change value to change the number of years to show
    if (this.props.endYear) {
      min = this.props.endYear
    }
    var years = []
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    if (!this.props.hideArchive) {
      years.push('Archive')
    }

    return years.map((item, index) => {
      return (
        <li className="nav-item">
          <a
            className={
              this.state.activeItem == item
                ? 'nav-link text-active-primary pb-4 active'
                : 'nav-link text-active-primary pb-4'
            }
            data-bs-toggle="tab"
            onClick={() => this._onItemClicked(item)}
          >
            {item}
          </a>
        </li>
      )
    })
  }

  render() {
    return (
      <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-4 border-0">
        {this._renderItems()}
      </ul>
    )
  }
}

YearPicker.defaultProps = {
  hideArchive: false,
}
