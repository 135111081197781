import React from 'react'

import Dropzone from 'react-dropzone-uploader'

import ContentLoader from 'react-content-loader'

import 'react-dropzone-uploader/dist/styles.css'

import fileImg from '../../../assets/media/misc/file-icon.png'

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
export default class CustomDropzone extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      type: props.type,
      files: props.file ? [props.file] : props.files,
      multi: props.multi,
    }

    this.store = {}
  }

  _uploadFile(data) {
    this.setState({ fileLoading: true })
    this.props.isUploading(true)
    if (this.props.type === 'image') {
      Backend.uploadImage(data)
        .then((file) => {
          this.setState({ file, fileLoading: false }, () => {
            this.props.isUploading(false)
            this.props.onUpload(file)
          })
        })
        .catch((error) => {
          this.setState({ fileLoading: false })
          this.props.isUploading(false)
          console.log('ERROR', error)
        })
    } else {
      Backend.uploadFile(data)
        .then((file) => {
          this.setState({ file, fileLoading: false }, () => {
            this.props.onUpload(file)
          })
        })
        .catch((error) => {
          this.setState({ fileLoading: false })
          console.log('ERROR', error)
        })
    }
  }

  render() {
    let { loading, files } = this.state

    if (loading) {
      return (
        <ContentLoader speed={0.5} style={{ width: '300px', height: 120 }}>
          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </ContentLoader>
      )
    }

    let contentType = this.props.type === 'photo' ? 'image' : this.props.type

    if (contentType === 'pdf') {
      contentType = 'application/pdf'
    } else {
      contentType += '/*'
    }

    let className = 'dropzone '
    if (this.props.variation) {
      className += this.props.variation
    }

    return (
      <div className={className}>
        <label className="drop-label">
          <input style={{ display: 'none' }} />
          <Dropzone
            onChangeStatus={({ meta, file }, status) => {
              this.setState({ file }, () => {
                if (status === 'removed') {
                  this.props.onRemove()
                }
                if (status === 'preparing') {
                  // do nothing
                }
                if (status === 'done') {
                  this._uploadFile(file)
                }
              })
            }}
            maxFiles={1}
            accept={`${contentType}`}
            multiple={false}
            styles={{
              dropzone: {
                width: 300,
                height: 50,
                minHeight: 60,
                border: 'none',
                cursor: 'pointer',
              },
              dropzoneReject: {
                borderColor: '#f17373',
                backgroundColor: '#f1abab',
              },
            }}
            inputContent={
              <div className="dz-message needsclick">
                {files && (
                  <div className="upload-file">
                    {' '}
                    <i className="bi bi-file-earmark-arrow-up text-primary fs-3x" />
                  </div>
                )}
                {files.length > 0 ? (
                  <></>
                ) : (
                  <>
                    <div className="ms-4">
                      <h3 className="fs-5 fw-bolder text-gray-900 mb-1">
                        Drop file here or click to upload.
                      </h3>
                      <span className="fs-7 fw-bold text-gray-400">
                        {this.props.subtitle}
                      </span>
                    </div>
                  </>
                )}
              </div>
            }
          />
        </label>
      </div>
    )
  }
}

CustomDropzone.defaultProps = {
  type: 'photo',
  files: [],
  multi: false,
}
