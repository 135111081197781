import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import Dashboard from "./Dashboard";
import OutstandingDebt from "./OutstandingDebt";
import Reports from "./Reports";
import CreditRatings from "./CreditRatings";
import Files from "./Files";
import Presentations from "./Presentations";
import Others from "./Others";
import Sustainability from "./Sustainability";
import Users from "./Users";
import Emails from "./Emails";
import AdminUsers from "./AdminUsers";
import Account from "./Account";
import SiteControl from "./SiteControl";

import Header from "./components/Header";
import Footer from "./components/Footer";
// import MobileHeader from './components/MobileHeader'
// import UserPanel from './components/UserPanel'
import LeftMenu from "./components/LeftMenu";
import ScrollTop from "./components/ScrollTop";

import MultiFactorAuthentication from "./components/multi-factor-authentication/MultiFactorAuthentication";
import MFAAlert from "./components/alert/MFAAlert";


export default class App extends React.Component {
  render() {
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <LeftMenu />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <Header />

              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div>
                  <div style={{ width: "95%", margin: "0 auto" }}>
                    <MFAAlert />
                  </div>

                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route
                      exact
                      path="/outstanding-debt"
                      component={OutstandingDebt}
                    />
                    <Route exact path="/reports" component={Reports} />
                    <Route
                      exact
                      path="/credit-ratings"
                      component={CreditRatings}
                    />
                    <Route exact path="/files" component={Files} />
                    <Route
                      exact
                      path="/presentations"
                      component={Presentations}
                    />
                    <Route exact path="/other-files" component={Others} />
                    {/* <Route
                      exact
                      path="/orix-announcement"
                      component={OrixAnnouncements}
                    /> */}
                    <Route
                      exact
                      path="/sustainability-reports"
                      component={Sustainability}
                    />
                    <Route exact path="/investors" component={Users} />
                    <Route exact path="/emails" component={Emails} />
                    <Route exact path="/admin-users" component={AdminUsers} />
                    <Route exact path="/account" component={Account} />
                    <Route exact path="/site-control" component={SiteControl} />
                    <Route
                      exact
                      path="/multi-factor-authentication"
                      component={MultiFactorAuthentication}
                    />
                    <Redirect to="/404" />
                  </Switch>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ScrollTop />
      </>
    );
  }
}
